











































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { axios } from '../lib/util';

type Prefectures = [string, string][];
type Cities = [string, string][];

@Component
export default class PrefCitySelect extends Vue {
  cities: Cities = [];
  prefectureUrlWord = '';
  cityCode = '';

  @Prop({ default: 'prefecture_url_word' }) readonly prefectureElementName!: string;
  @Prop({ default: 'city_code' }) readonly cityElementName!: string;
  @Prop(Array) readonly prefectures!: Prefectures;
  @Prop(String) readonly defaultPrefectureUrlWord!: string;
  @Prop(String) readonly defaultCityCode!: string;
  @Prop(String) readonly getCitiesUrl!: string;

  constructor(...args: any[]) {
    super(...args);
    this.prefectureUrlWord = this.defaultPrefectureUrlWord || '';
    this.cityCode = this.defaultCityCode || '';
  }

  mounted(): void {
    this.fetchCities();
  }

  @Emit()
  private handleChangePrefecture(e): void {
    this.prefectureUrlWord = e.target.value;
    this.cityCode = '';

    this.fetchCities();
  }

  @Emit()
  private async handleChangeCity(e): Promise<void> {
    this.cityCode = e.target.value;
  }

  private async fetchCities(): Promise<void> {
    if (this.prefectureUrlWord) {
      const data = await axios().get(`${this.getCitiesUrl}?prefecture_url_word=${this.prefectureUrlWord}`)
        .then(response => response.data);
      this.cities = data.cities;
    } else {
      this.cities = [];
    }
  }
}
