






import { Component, Emit, Ref, Vue } from 'vue-property-decorator';

@Component
export default class BackToTop extends Vue {
  timer: number | null = null;

  mounted() {
    window.addEventListener('scroll', this.toggleVisible);
  }

  @Ref()
  readonly anchor!: HTMLAnchorElement;

  @Emit()
  private backToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  private toggleVisible(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = window.setTimeout(() => {
      if (window.scrollY === 0) {
        this.anchor.style.display = 'none';
      } else {
        this.anchor.style.display = 'block';
      }
    }, 100);
  }
}
