import { useState } from "react";

const OurJobComponent = () => {
  const [selectedJobSection, setSelectedJobSection] = useState('ourjob-treatment');

  type OurjobImage = {
    src: string;
    alt: string;
  }

  type OurJobSection = {
    text: string[];
    showImage: OurjobImage;
    showDetailImage: OurjobImage;
    className: string;
    pc: OurjobImage
    sp: OurjobImage;
  }

  const ourJobSections: OurJobSection[] = [
    {
      text: [
        'お客様のお疲れ状況をヒアリングし、最適な施術を提案します。',
        'またほぐすだけではなく、お客様にあったセルフケアの提案や健康な生活を送るためのアドバイスも行います。',
      ],
      className: 'ourjob-treatment',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-treatment.png',
        alt: '施術',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-treatment-detail.png',
        alt: '施術',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-treatment-pc.jpg',
        alt: '施術',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-treatment-sp.jpg',
        alt: '施術',
      }
    },
    {
      text: [
        'リラクでは施術だけではなく、お疲れの箇所のヒアリング、日々の生活習慣へのアドバイスなど、1人1人のお客様に誠心誠意をもって向き合います。コミュニケーションも大事な私たちセラピストのお仕事です。',
      ],
      className: 'ourjob-communication',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-communication.png',
        alt: 'コミュニケーション',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-communication-detail.png',
        alt: 'コミュニケーション',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-communication-pc.jpg',
        alt: 'コミュニケーション',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-communication-sp.jpg',
        alt: 'コミュニケーション',
      }
    },
    {
      text: [
        'お店がどうすればよくなるのか、大切なお客様により良いサービスを提供するにはどうすればいいのか。',
        'お店のメンバーで月に1～2回ミーティングを行います。',
      ],
      className: 'ourjob-meeting',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-meeting.png',
        alt: '店舗ミーティング',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-meeting-detail.png',
        alt: '店舗ミーティング',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-meeting-pc.jpg',
        alt: '店舗ミーティング',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-meeting-sp.jpg',
        alt: '店舗ミーティング',
      }
    },
    {
      text: [
        '繁盛店の条件は、お店が清潔であること。',
        'お客様に快適な空間を提供するため、毎日スタジオチェックを行います。',
      ],
      className: 'ourjob-studio',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-studio.png',
        alt: 'スタジオチェック',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-studio-detail.png',
        alt: 'スタジオチェック',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-studio-pc.jpg',
        alt: 'スタジオチェック',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-studio-sp.jpg',
        alt: 'スタジオチェック',
      }
    },
    {
      text: [
        '店長と定期的に面談を行います。',
        '目標設定やセラピストとしてのキャリアを考える重要な時間です。',
        '将来どんなセラピストになりたいのか面談で話し合います。',
      ],
      className: 'ourjob-interview',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-interview.png',
        alt: '店長面談',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-interview-detail.png',
        alt: '店長面談',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-interview-pc.jpg',
        alt: '店長面談',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-interview-sp.jpg',
        alt: '店長面談',
      }
    },
    {
      text: [
        'お客様にお店に来てもらえるよう、お店を知ってもらえるよう、チラシ配りを行います。',
      ],
      className: 'ourjob-flyer',
      showImage: {
        src: '/img/recruit/ourjob/ourjob-flyer.png',
        alt: 'チラシ配り',
      },
      showDetailImage: {
        src: '/img/recruit/ourjob/ourjob-flyer-detail.png',
        alt: 'チラシ配り',
      },
      pc: {
        src: '/img/recruit/ourjob/ourjob-flyer-pc.jpg',
        alt: 'チラシ配り',
      },
      sp: {
        src: '/img/recruit/ourjob/ourjob-flyer-sp.jpg',
        alt: 'チラシ配り',
      }
    },
  ];
  
  const selectSection = (showImage: string) => {
    setSelectedJobSection(showImage);
  };
  
  const selectedJobDetails = ourJobSections.find(section => section.className === selectedJobSection);

  return (
    <div className="recruit-ourjob">
      <h2 className="ourjob-title-pc"><img src="/img/recruit/ourjob/ourjob-title-pc.png" alt="仕事内容" /></h2>
      <h2 className="ourjob-title-sp"><img src="/img/recruit/ourjob/ourjob-title-sp.png" alt="仕事内容" /></h2>

      <div className="ourjob-container">
        <div className="ourjob-content">
          <div className="ourjob-content-1">
            {ourJobSections.slice(0, 3).map((section, index) => (
              <div
                key={index} 
                className={`${section.className} ${selectedJobSection === section.className}`}
                onClick={() => selectSection(section.className)}
                onMouseEnter={() => selectSection(section.className)}
              >   
                <img
                  src={selectedJobSection === section.className
                    ? section.showDetailImage.src
                    : section.showImage.src
                  }
                  alt={selectedJobSection === section.className
                    ? section.showDetailImage.alt
                    : section.showImage.alt
                  }
                  className="ourjob-section-img"
                />
              </div>
            ))}
          </div>

          <div className="ourjob-content-2">
            {ourJobSections.slice(3).map((section, index) => (
              <div
                key={index} 
                className={`${section.className} ${selectedJobSection === section.className}`} 
                onClick={() => selectSection(section.className)}
                onMouseEnter={() => selectSection(section.className)}
              >
                <img
                  src={selectedJobSection === section.className
                    ? section.showDetailImage.src
                    : section.showImage.src
                  }
                  alt={selectedJobSection === section.className
                    ? section.showDetailImage.alt
                    : section.showImage.alt
                  }
                  className="ourjob-section-img"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="ourjob-content-details-pc">
          {selectedJobDetails && <img src={selectedJobDetails.pc.src}  />}
          {selectedJobDetails && selectedJobDetails.text.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>

        <div className="ourjob-content-details-sp">
          {selectedJobDetails && <img src={selectedJobDetails.sp.src}  />}
          {selectedJobDetails && selectedJobDetails.text.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </div>
      </div>

      <div className="ourjob-button">
        <img src="/img/recruit/ourjob/ourjob-button-title.png" alt="アットホームな職場で働きませんか" className="ourjob-title"/>
        <a href="/jobs" className="ourjob-link">
          <img src="/img/recruit/recruit-search.svg" className="ourjob-link-img"/>
          <span>近くのセラピスト求人を探す</span>
        </a>
      </div>
    </div>
  );
};

export default OurJobComponent;