import React from 'react';
import ReactDOM from 'react-dom';
import { getRailsComponent } from './componentRegistry';

const targetHtmlElements = (): HTMLCollectionOf<Element> => {
  return document.getElementsByClassName('js-react-on-rails-component');
};

const render = (element: Element) => {
  const componentName = element.getAttribute('data-component-name');
  const domId = element.getAttribute('data-dom-id');
  if (!componentName || !domId) {
    return;
  }

  const elementConstructor = getRailsComponent(componentName);
  const targetNode = document.getElementById(domId);
  if (!elementConstructor || !targetNode) {
    return;
  }

  const props = (element.textContent !== null) ? JSON.parse(element.textContent) : {};

  ReactDOM.render(
    <React.StrictMode>
      {React.createElement(elementConstructor, props)}
    </React.StrictMode>
    , targetNode);
};

export const renderRailsElements = () => {
  const elements = targetHtmlElements();
  for (let i = 0; i < elements.length; i++) {
    render(elements[i]);
  }
};
