















































import { Component, Emit, Ref, Vue } from 'vue-property-decorator';

@Component
export default class FaqMenu extends Vue {
  headerHeight = (document.querySelector('#header') as HTMLDivElement).clientHeight;
  isFixed = false;
  timer: number | null = null;

  @Ref()
  readonly faqMenu!: HTMLDivElement;

  private mounted(): void {
    window.addEventListener('scroll', this.toggleFixed);
  }

  private static calcFixedHeight(): number {
    return (document.querySelector('#link01') as HTMLDivElement).getBoundingClientRect().top + window.pageYOffset;
  }

  private toggleFixed(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    const listItem = this.faqMenu.querySelector('.side-bar-faq .list-item') as HTMLDivElement;

    this.timer = window.setTimeout(() => {
      if (window.scrollY < FaqMenu.calcFixedHeight()) {
        this.faqMenu.style.top = 'initial';
        this.faqMenu.classList.remove('fixed');
        listItem.style.top = 'initial';
      } else {
        this.faqMenu.classList.add('fixed');
        listItem.style.top = `${this.headerHeight + 20}px`;
      }
    }, 10);
  }

  @Emit()
  private scrollTo(event): void {
    const selector = event.target.dataset.link;
    this.removeActiveLink();
    event.target.parentElement.classList.add('active');

    window.scrollTo({
      top: (document.querySelector(selector) as HTMLDivElement).getBoundingClientRect().top + window.pageYOffset - this.headerHeight,
      behavior: 'smooth'
    });
  }

  private removeActiveLink(): void {
    this.faqMenu.querySelectorAll('.item.active').forEach((element) => {
      element.classList.remove('active');
    });
  }
}
