





























import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { MOBILE_BREAK_POINT } from '../lib/util';

@Component
export default class FaqItem extends Vue {
  visible = window.innerWidth > MOBILE_BREAK_POINT;
  isMobile = window.innerWidth <= MOBILE_BREAK_POINT;

  @Prop(String)
  readonly question!: string;

  @Prop({ default: false })
  readonly defaultVisible!: boolean;

  mounted(): void {
    if (this.isMobile) {
      this.visible = this.defaultVisible;
    }
    window.addEventListener('resize', this.toggle);
  }

  private toggle(): void {
    // FIXME: どういうこと？
    // eslint-disable-next-line no-mixed-operators
    if (this.isMobile !== window.innerWidth <= MOBILE_BREAK_POINT) {
      this.isMobile = window.innerWidth <= MOBILE_BREAK_POINT;
      this.isMobile ? this.hidden() : this.show();
    }
  }

  @Emit()
  private show(): void {
    this.visible = true;
  }

  @Emit()
  private hidden(): void {
    this.visible = false;
  }
}
