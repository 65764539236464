import Vue from 'vue';
import objectFitImages from 'object-fit-images';
import '../styles/site/styles.scss';
import VueSlick from 'vue-slick';
import { Plugin } from 'vue-fragment';
import BackToTop from '../javascripts/site/BackToTop.vue';
import MobileDrawerMenu from '../javascripts/site/MobileDrawerMenu.vue';
import RecruitFieldsWrapper from '../javascripts/site/RecruitFieldsWrapper.vue';
import PrefCitySelect from '../javascripts/site/PrefCitySelect.vue';
import FaqMenu from '../javascripts/site/FaqMenu.vue';
import FaqItem from '../javascripts/site/FaqItem.vue';
import PrefectureLinksWrapper from '../javascripts/site/PrefectureLinksWrapper.vue';
import { addRailsComponent } from '../javascripts/react/rails2React/componentRegistry';
import { renderRailsElements } from '../javascripts/react/rails2React/render';
import InterviewComponent from '../javascripts/react/components/rails/recruits/Interview';
import CollegeComponent from '../javascripts/react/components/rails/recruits/College';
import OurJobComponent from '../javascripts/react/components/rails/recruits/OurJob';
import FaqComponent from '../javascripts/react/components/rails/recruits/Faq';
import PrivacyPolicyComponent from '../javascripts/react/components/rails/JobPostingApplications/PrivacyPolicyModal';
// import PostalCode from '../javascripts/site/PostalCode.vue';

window.onload = (): void => {
  objectFitImages();
};

// direct upload
// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()

Vue.use(Plugin);
Vue.component('slick-carousel', VueSlick);
Vue.component('back-to-top', BackToTop);
Vue.component('mobile-drawer-menu', MobileDrawerMenu);
// Vue.component('postal-code', PostalCode);
Vue.component('recruit-fields-wrapper', RecruitFieldsWrapper);
Vue.component('pref-city-select', PrefCitySelect);
Vue.component('faq-menu', FaqMenu);
Vue.component('faq-item', FaqItem);
Vue.component('prefecture-links-wrapper', PrefectureLinksWrapper);
addRailsComponent('InterviewComponent', InterviewComponent);
addRailsComponent('CollegeComponent', CollegeComponent);
addRailsComponent('OurJobComponent', OurJobComponent);
addRailsComponent('FaqComponent', FaqComponent);
addRailsComponent('PrivacyPolicyComponent', PrivacyPolicyComponent);

document.addEventListener('DOMContentLoaded', () => {
  new Vue({ el: '#app' });
  renderRailsElements();
});