












import { Component, Emit, Ref, Vue } from 'vue-property-decorator';

@Component
export default class MobileDrawerMenu extends Vue {
  @Ref()
  readonly icon!: HTMLSpanElement;

  @Emit()
  private open(): void {
    this.icon.classList.add('mobile-close');
    const nav = document.querySelector('.nav-menu');
    if (nav) {
      nav.classList.add('active');
    }
    const html = document.querySelector('html');
    if (html) {
      html.classList.add('is-locked');
    }
  }

  @Emit()
  private close(): void {
    this.icon.classList.remove('mobile-close');
    const nav = document.querySelector('.nav-menu');
    if (nav) {
      nav.classList.remove('active');
    }
    const html = document.querySelector('html');
    if (html) {
      html.classList.remove('is-locked');
    }
  }
}
