import { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import SwiperCore from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CollegeComponent = () => {
  const swiperRef = useRef<SwiperCore | null>(null);

  const handlePrevClick = () => {
    if(swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNextClick = () => {
    if(swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  type collegeImage = {
    pc: string;
    sp: string;
  }

  const collegeImages: collegeImage[] = [
    {
      pc: "/img/recruit/college/college-01-pc.jpg",
      sp: "/img/recruit/college/college-01-sp.jpg",
    },
    {
      pc: "/img/recruit/college/college-02-pc.jpg",
      sp: "/img/recruit/college/college-02-sp.jpg",
    },
    {
      pc: "/img/recruit/college/college-03-pc.jpg",
      sp: "/img/recruit/college/college-03-sp.jpg",
    },
  ];

  return (
    <div className="recruit-college">
      <h2 className="college-title-pc"><img src="/img/recruit/college/college-title-pc.png" alt="カレッジ" /></h2>
      <h2 className="college-title-sp"><img src="/img/recruit/college/college-title-sp.png" alt="カレッジ" /></h2>

      <div className="college-container">
        <div className="college-content">
          <div className="college-content-text">
            <div className="college-title">
              <h3>仲間と一緒に成長できる</h3>
              <h3>研修施設</h3>
            </div>
            <div className="college-text-pc">
              <p>リラクには、接遇・知識・技術を無料で学べる研修施設</p>
              <p>「リラクカレッジ」があります。入店前に同じ志をもった</p>
              <p>仲間に出会い、ともに成長することができます。</p>
            </div>
            <div className="college-text-sp">
              <p>リラクには、接遇・知識・技術を無料で学べる研修</p>
              <p>施設「リラクカレッジ」があります。入店前に同じ</p>
              <p>志をもった仲間に出会い、ともに成長することがで</p>
              <p>きます。</p>
            </div>
          </div>
        
          <Swiper
            className="college-swiper"
            slidesPerView={1}
            loop={true}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{ clickable: true }}
            
            autoplay={{ delay: 5000 }}
            breakpoints={{
              769: {
                slidesPerView: 1,
                navigation: false,
              },
            }}
          >
            {collegeImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image.pc} alt={`カレッジ${index + 1}`} className="college-swiper-pc" />
                <img src={image.sp} alt={`カレッジ${index + 1}`} className="college-swiper-sp" />
              </SwiperSlide>
            ))}
          </Swiper>

          <img src="/img/recruit/recruit-chevron-left.svg" alt="前へ" className="swiper-button-prev" onClick={handlePrevClick} />
          <img src="/img/recruit/recruit-chevron-right.svg" alt="次へ" className="swiper-button-next" onClick={handleNextClick} />
        </div>

        <div className="lecturer-content">
          <img src="/img/recruit/college/college-lecturer-pc.jpg" alt="カレッジ講師" className="lecture-img-pc" />
          <img src="/img/recruit/college/college-lecturer-sp.jpg" alt="カレッジ講師" className="lecture-img-sp" />
          
          <div className="message-content-text-pc">
            <img src="/img/recruit/college/lecture-message-pc.png" alt="講師メッセージ" className="message-img-pc" />

            <div className="message-text-pc">
              <p>
                こんにちは！<br />
                リラクカレッジでは、しっかりしたサポート体制のもと、
                毎日多くの研修生が新たな知識や技術の習得に励んでいます。
                皆さまと一緒に働けることを講師陣一同、楽しみにしております！
              </p>
            </div>
            <div className="nationwide-content-pc">
              <img src="/img/recruit/college/college-nationwide-pc.png" alt="全国のカレッジ" className="nationwide-img-pc" />
              <div className="nationwide-text-pc">
                <h4>全国の</h4>
                <h4>リラクカレッジ</h4>
              </div>
              <ul className="nationwide-list-pc">
                <li>
                  <span>東京</span>
                  <p>お台場カレッジ</p>
                </li>
                <li>
                  <span>大阪</span>
                  <p>大阪梅田カレッジ</p>
                </li>
                <li>
                  <span>愛知</span>
                  <p>名古屋カレッジ</p>
                </li>
              </ul>
            </div>
          </div>
          
          <div className="message-content-text-sp">
            <img src="/img/recruit/college/lecture-message-sp.png" alt="講師メッセージ" className="message-img-sp" />
            <div className="message-text-sp">
              <p>
                こんにちは！<br />
                リラクカレッジでは、しっかりしたサポート体制のもと、
                毎日多くの研修生が新たな知識や技術の習得に励んでいます。
                皆さまと一緒に働けることを講師陣一同、楽しみにしております！
              </p>
            </div>
          </div>
          <div className="nationwide-content-sp">
            <img src="/img/recruit/college/college-nationwide-sp.png" alt="全国のカレッジ" className="nationwide-img-sp" />
            <div className="nationwide-text-sp">
              <h4>全国のリラクカレッジ</h4>
              <ul className="nationwide-list-sp">
                <li>
                  <span>東京</span>
                  <p>お台場カレッジ</p>
                </li>
                <li>
                  <span>大阪</span>
                  <p>大阪梅田カレッジ</p>
                </li>
                <li>
                  <span>愛知</span>
                  <p>名古屋カレッジ</p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="college-button">
          <img src="/img/recruit/college/college-button-title.png" alt="充実の研修サポート体制" className="college-title"/>
          <a href="/college" className="college-link">
            <img src="/img/recruit/college/college.svg" className="college-link-img"/>
            <span>カレッジについて詳しくみる</span>
          </a>
        </div>

        <div className="process-content-pc">
          <div className="process-content-title-pc">
            <h3>デビューまでの流れ</h3>
            <p>1〜2ヶ月</p>
          </div>
          <div className="process-content-pc-img">
            <img src="/img/recruit/college/process-pc.png" alt="デビューまでの流れ" className="process-img-pc" />
          </div>
        </div>

        <div className="process-content-sp">
          <h3>デビューまでの流れ</h3>
          <div className="process-content-sp-img">
            <img src="/img/recruit/college/process-sp.png" alt="デビューまでの流れ" className="process-img-sp" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeComponent;