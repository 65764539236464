import { useState } from "react";

const FaqComponent = () => {
  const [openAnswer, setOpenAnswer] = useState<number | null>(null);

  type QuestionAndAnswer = {
    question: string;
    answer: string;
  };

  const questionAndAnswer: QuestionAndAnswer[] = [
    {
      question: "研修の期間はありますか？",
      answer: "研修初日に研修受講のスケジュールを立てて頂き、最長2ヶ月で店舗デビューを目指して頂いてます。",
    },
    {
      question: "研修期間は最短でどれくらいですか？",
      answer: "研修期間は最短で約２週間になります。ただし、入店予定の店舗で提供する手技の都合や個人差などもあるので、研修期間は人によって異なります。",
    },
    {
      question: "今までどんな業界で働いていた方がいますか？",
      answer: "様々な業界から転職してくる方がいます。サービス業従事者も多く、介護職、看護師、保育士、接客業、インストラクターなどお客様の笑顔を見ることが好きな方が多い傾向があります。また、子育てを終えた方などセカンドキャリアとしてチャレンジする方も多くいます。"
    },
    {
      question: "応募の年齢制限はありますか？",
      answer: "年齢制限は設けておりません。どなたでもご応募頂けます。",
    }
  ];

  const toggleAnswer = (index: number) => {
    setOpenAnswer(openAnswer === index ? null : index);
  };

  return (
    <div className="recruit-faq">
      <h2 className="faq-title-pc"><img src="/img/recruit/faq/faq-title-pc.png" alt="FAQ" /></h2>
      <h2 className="faq-title-sp"><img src="/img/recruit/faq/faq-title-sp.png" alt="FAQ" /></h2>

      <div className="faq-container">
        {questionAndAnswer.map((faq, index) => (
          <div
            key={index}
            className={`${index === questionAndAnswer.length - 1 ? 'faq-item-last' : ''}`}
          >
            <button
              className={`faq-question ${openAnswer === index ? 'faq-question-open' : ''}`}
              onClick={() => toggleAnswer(index)}
            >
              {faq.question}

              {openAnswer === index ? (
                <img src="/img/recruit/faq/faq-close.svg" alt="閉じる" />
              ) : (
                <img src="/img/recruit/faq/faq-open.svg" alt="開く" />
              )}
            </button>
            {openAnswer === index && (
              <div className="faq-answer">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
        <a href="/faq" className="faq-more">
          <span>MORE</span>
          <img src="/img/recruit/faq/faq-more.svg" alt="FAQをもっと見る" />
        </a>
      </div>
    </div>
  );
};

export default FaqComponent;