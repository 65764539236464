import { JSXElementConstructor } from 'react';

const registries: { [key: string]: JSXElementConstructor<any> } = {};

export const addRailsComponent = (name: string, constructor: JSXElementConstructor<any>) => {
  registries[name] = constructor;
};

export const getRailsComponent = (name: string): JSXElementConstructor<any> | undefined => {
  return registries[name];
};
